<template>
  <div v-if='isEditing'>
    <v-container fluid>
      <v-tabs v-model='tab' background-color='transparent'>
        <v-tab>Geral</v-tab>
        <!-- <v-tab>Especificações</v-tab> -->
        <v-tab>Serviços</v-tab>
      </v-tabs>
      <v-container fluid>
        <v-tabs-items v-model='tab' class='transparent'>
          <v-tab-item>
            <v-row no-gutters justify='center'>
              <customers-form :is-editing='isEditing' :customer-id='customerId' v-on='$listeners' />
            </v-row>
          </v-tab-item>
          <!-- <v-tab-item>
            <customers-specifications :customer-id='customerId' />
          </v-tab-item> -->
          <v-tab-item>
            <customers-licenses :customer-id='customerId' />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-container>
  </div>
  <div v-else>
    <v-container fluid fill-height style='height: 90vh'>
      <v-row justify='center' align-center>
        <customers-form :is-editing='isEditing' :customer-id='customerId' v-on='$listeners' />
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'CustomersDetails',
    components: {
      CustomersForm: () => import('./CustomersForm'),
      // CustomersSpecifications: () => import('./CustomersSpecifications'),
      CustomersLicenses: () => import('./CustomersLicenses'),
    },
    props: {
      isEditing: Boolean,
      customerId: {
        type: Number,
        default: 0,
      },
    },
    data: function () {
      return {
        tab: null,
      };
    },
  };
</script>
